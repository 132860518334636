import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from '@app/material.module';
import { ShellMenuToggleComponent } from './shell-menu-toggle.component';

@NgModule({
  declarations: [ShellMenuToggleComponent],
  imports: [CommonModule, MaterialModule, MatIconModule],
  exports: [ShellMenuToggleComponent],
})
export class ShellMenuToggleModule {}
